import React from 'react';

import Home from '../modules/pages/home/Home';

const home = () => {
  return (
    <Home
      seo={{
        description:
          'Understanding your customer has never been so easy! Integrate today and create your analysis in a few minutes.',
      }}
    />
  );
};

export default home;
